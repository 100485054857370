import * as React from 'react'
import SbEditable from 'storyblok-react'

import useStoryblok from '../lib/storyblok'

import Seo from '../components/shared/seo'
import Layout from '../components/layout/layout'
import DynamicComponent from "../components/dynamicComponent"

const Page = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  if (!story.content.body) {
    return null
  }

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  return (
    <Layout>
      <Seo lang="de" title={story.name} />
      <SbEditable content={story.content}>
        { components }
      </SbEditable>
    </Layout>
  )}

export default Page
